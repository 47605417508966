import { msalInstance } from "../auth";
import { getUserPreferredLanguage } from "../helper";

if (typeof window.hj !== "undefined") {
  const account = msalInstance.getActiveAccount();
  const name = account?.name;
  const userName = account?.username;
  const roles = account?.idTokenClaims?.roles;
  const language = getUserPreferredLanguage();

  window.hj("identify", userName || null, {
    language,
    email: userName,
    roles: roles?.join(", "),
    name: !userName ? undefined : name,
  });
}
